import httpService from './http.service';


const fetchParkingForConfiguration = (subjectId) => httpService
    .get(`/parkings${subjectId ? `/${subjectId}` : '/0'}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const fetchParkingAccesses = (parkingId, accessType) => httpService
    .get(`/parkingaccess/${parkingId}?accessType=${accessType}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const fetchParkingEventCategories = (parkingId) => httpService
    .get(`/eventcategory/${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const updateParking = (parking) => httpService
    .put('/parking', parking)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateParkingSimple = (parking) => httpService
    .put('/parking/simple', parking)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const fetchParkingSimple = (parkingId) => httpService
    .get(`/parking/simple/${parkingId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const createParking = (parking) => httpService
    .post('/parking', parking)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const tryConnectParking = (parking) => httpService
    .post('/parking/connect/try', parking)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });


const tryTerminate = (parking) => httpService
    .post(`/parking/${parking.parkingId}/terminate`)
    .then(({data}) => data)
    .catch((err) => {
            throw err;
        }
    );


const updateEventCategory = (category) => httpService
    .put('/eventcategory', category)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const updateZoneVisibility = (zoneId, show) => httpService
    .post(`/zone/${zoneId}/visibility?show=${show}`)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });


const reconnect = (parkingId, force) => httpService
    .post(`/parking/${parkingId}/reconnect?force=${force}`)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

export default {
    tryConnectParking,
    createParking,
    updateZoneVisibility,
    fetchParkingEventCategories,
    fetchParkingAccesses,
    updateParking,
    updateParkingSimple,
    fetchParkingSimple,
    updateEventCategory,
    fetchParkingForConfiguration,
    reconnect,
    tryTerminate,
};
