import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import {ActionButton} from 'components/common';
import {CircularProgress} from "@material-ui/core";
import {fetchInfoBarDetail} from "../../../redux/actions/infoBars";
import InfoBarView from './InfoBarView';
import RichTextEditor from 'react-rte';
import { isAdmin } from 'utils';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 855,
            minHeight: '90%',
            maxWidth: 'unset',
            // boxShadow: theme.palette.shadow.main,
        },
        height: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: 0,
        paddingBottom: theme.spacing(4),

    },
    button: {
        width: 180,
        height: 40,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 14,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));

function InfoBarModal(props) {
    const {
        userUseCases,
        handleClose,
        saveInfoBar,
        infoBarConfigModel,
        saving,
        setSaving,
        subjects
} = props;


    const classes = useStyles();
    const {t} = useTranslation();

    const [updatedInfoBar, setUpdatedInfoBar] = useState();
    const [htmlContent, setHtmlContent] = useState();
    const [errorStep1, setErrorStep1] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchedBranches, setIsFetchedBranches] = useState(false);

    const user = useSelector((state) => state.authData.user);

    const handleCloseLoc = () => {
        handleClose();
    }

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedInfoBar.title || updatedInfoBar.title.trim().length === 0) {
            error.title = 'required';
            ret = false;
        }
        if (!updatedInfoBar.active) {
            error.active = 'required';
            ret = false;
        }
        if (!updatedInfoBar.subjectId && !isAdmin(user) ) {
            error.subjectId = 'required';
            ret = false;
        }
        setErrorStep1(error);
        return ret;
    }

    useEffect(() => {
        if ( updatedInfoBar ) {
            if ( updatedInfoBar.content ) {
                setHtmlContent(RichTextEditor.createValueFromString(updatedInfoBar.content, 'html'));
            } else {
                setHtmlContent(RichTextEditor.createEmptyValue());
            }
        }
      }, [updatedInfoBar]);

    const handleChange = (name) => (event) => {
        let tmpInfoBar = {...updatedInfoBar, content: htmlContent.toString('html')};
        if ( name === 'content' ) {
            setHtmlContent(event);
            return;
        } else if (event && event.target) {
            tmpInfoBar = {...tmpInfoBar, [name]: event.target.value};
        } else {
            tmpInfoBar = {...tmpInfoBar, [name]: event};
        }
        if (name==='subjectId') {
            setIsFetchedBranches(false);
        }
        if (name==='showPermanent' && event===true) {
            tmpInfoBar = {...tmpInfoBar, 'show': 0};
        }
        setUpdatedInfoBar(tmpInfoBar);
    }

    useEffect(()=>{
        if ( infoBarConfigModel.infoBar ) {
            // setUpdatedInfoBar({...infoBarConfigModel.infoBar, initialized:false});
            setUpdatedInfoBar({...infoBarConfigModel.infoBar});
        }
    }, [infoBarConfigModel]);

    return (
        <Dialog
            className={classes.container}
            open={infoBarConfigModel.open||false}
            onClose={handleCloseLoc}
        >
            <DialogTitle className={classnames(classes.title)}>
                <span>
                    {t(infoBarConfigModel.readOnly?'DETAIL_INFO_BAR':(infoBarConfigModel.isNew ? 'CREATE_INFO_BAR' : 'UPDATE_INFO_BAR'))}
                </span>
            </DialogTitle>

            <DialogContent className={classes.content}>
                <div className={classes.container}>
                    <InfoBarView 
                        htmlContent={htmlContent}
                        handleChange={handleChange}
                        updatedInfoBar={updatedInfoBar}
                        setUpdatedInfoBar={setUpdatedInfoBar}
                        error={errorStep1}
                        readOnly={infoBarConfigModel.readOnly}
                        isNew={infoBarConfigModel.isNew}
                        loading={isLoading}
                        saving={saving}
                        isFetchedBranches={isFetchedBranches}
                        subjects={subjects}
                        setIsFetchedBranches={setIsFetchedBranches}
                    />
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <div className={classes.wrapper}>
                        {!infoBarConfigModel.readOnly &&
                        <ActionButton
                            action="close"
                            handleClick={handleCloseLoc}
                            className={classes.button}
                        />
                        }
                    </div>

                </div>
                <div className={classes.buttonNavigation}>
                    <div className={classes.wrapper}>
                        {!infoBarConfigModel.readOnly &&
                            <>
                                <ActionButton
                                    disabled={saving}
                                    action="save" handleClick={() => {
                                        const ret = isValid(infoBarConfigModel.isNew);
                                        if (ret) {
                                            setUpdatedInfoBar({...updatedInfoBar, content: htmlContent.toString('html')});
                                            saveInfoBar({...updatedInfoBar, content: htmlContent.toString('html')});
                                            setSaving(true);
                                        }
                                    }}
                                    className={classes.button}
                                />
                                {saving && <CircularProgress size={24} className={classes.buttonProgress} color='success'/>}
                            </>
                        }
                        {infoBarConfigModel.readOnly &&
                            <ActionButton
                                action="close"
                                handleClick={handleCloseLoc}
                                className={classes.button}
                            />
                        }                          
                    </div>
                </div>
            </DialogActions>
        </Dialog>

    );
}

const mapStateToProps = (store) => ({
    infoBar: store.infoBarData.infoBar,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchInfoBarDetail
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InfoBarModal);


