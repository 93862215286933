import React, {useEffect, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux/es';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {isComponentVisibleForUser} from 'utils';
import ParkingSymbol from "../../common/ParkingSymbol";
import {fetchParkingSimple} from '../../../redux/actions/parkings';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import PMCDialog from "../../common/PMCDialog";
import {SketchPicker} from "react-color";
import {DropzoneAreaBase} from 'material-ui-dropzone';


const useStyles = makeStyles((theme) => ({
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    myDropZone: {
        minHeight: '200px',
    },
    image: {
        height: '200px',
    },
}));

function ParkingSetup(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        parking,
        updateParking,
        handleClose,
        userUseCases,
        fetchParkingSimple,
        parkingSimple
    } = props;

    const [files, setFiles] = useState([]);
    const [isFetchedSimple, setIsFetchedSimple] = useState(false);

    useEffect(()=>{
        if ( parking && parking.parkingId && !isFetchedSimple ) {
            fetchParkingSimple(parking.parkingId).then(() => {
                setIsFetchedSimple(true);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            if ( parkingSimple ) {
                setState({...state, image: parkingSimple.image})
            }
        }
    }, [parkingSimple, fetchParkingSimple, isFetchedSimple])

    const [state, setState] = useState({
        parkingName: parking.parkingName,
        color: parking.color,
        address: parking.address,
        phone: parking.phone,
        email: parking.email,
        helpdeskPhone: parking.helpdeskPhone,
        connectionType: parking.connectionType
    });

    

    const handleChange = (field) => (event) => {
        switch (field) {
            case 'color':
                return setState({
                    ...state,
                    [field]: event.hex,
                });
            default:
                return setState({
                    ...state,
                    [field]: event.target.value,
                });
        }
    };

    const [error, setError] = useState(false);
    const handleSubmit = () => {
        setError(false);
        if (!state.parkingName) {
            setError(true);

            return;
        }
        if (!state.helpdeskPhone) {
            setError(true);

            return;
        }

        updateParking({
            parkingId: parking.parkingId,
            parkingName: state.parkingName,
            color: state.color,
            address: state.address,
            phone: state.phone,
            email: state.email,
            helpdeskPhone: state.helpdeskPhone,
            image: state.image
        });
        handleCloseInner();
    };

    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleAdd = (newFiles) => {
        setState({...state, image: newFiles[0].data.split('base64,')[1]})
        setFiles([...newFiles]);
    };

    const handleDelete = () => {
        setState({...state, image: undefined})
        setFiles([]);
    }

    const handleCloseInner = () => {
        setIsFetchedSimple(false);
        handleClose();
    }

    return (
        <PMCDialog
            size="lg"
            height="790"
            headerColor={"#333da9"}
            actionType="saveModal"
            open={opened}
            title={t('PARKING_SETTINGS')}
            onClose={handleCloseInner}
            onSubmit={isComponentVisibleForUser(UC_PARKING_EDIT, userUseCases) && handleSubmit}
        >
            <form autoComplete='off'>
                <Box m={4} height="100%">
                    <Grid container spacing={3}>
                        <Grid item sm={2}>
                            <div>
                                <div onClick={() => setDisplayColorPicker(true)}>
                                    <ParkingSymbol
                                        color={state.color}
                                        connectionType={state.connectionType}
                                        width={'66px'}
                                    />
                                </div>
                                {displayColorPicker ? <div className={classes.popover}>
                                    <div className={classes.cover}
                                            onClick={() => setDisplayColorPicker(false)}/>
                                    <SketchPicker color={state.color}
                                                    onChange={handleChange('color', false)}/>
                                </div> : null}

                            </div>
                        </Grid>

                        <Grid item xs sm={10}>
                            <TextField
                                autoFocus
                                helperText={error && error.parkingName
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                id="name"
                                value={state.parkingName || ''}
                                onChange={handleChange('parkingName', false)}
                                label={t('NAME')}/>
                        </Grid>

                        <Grid item xs sm={12}>
                            <TextField
                                helperText={error && error.address
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                id="name"
                                value={state.address || ''}
                                onChange={handleChange('address', false)}
                                label={t('ADDRESS')}/>
                        </Grid>

                        <Grid item xs sm={12}>
                            <TextField
                                helperText={error && error.email
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                id="email"
                                value={state.email || ''}
                                onChange={handleChange('email', false)}
                                label={t('EMAIL')}/>
                        </Grid>

                        <Grid item xs sm={6}>
                            <TextField
                                helperText={error && error.phone
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                id="phone"
                                value={state.phone || ''}
                                onChange={handleChange('phone', false)}
                                label={t('PHONE')}/>
                        </Grid>

                        <Grid item xs sm={6}>
                            <TextField
                                helperText={error && error.helpdeskPhone
                                    ?
                                    <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    : null}
                                fullWidth
                                required
                                id="helpdeskPhone"
                                value={state.helpdeskPhone || ''}
                                onChange={handleChange('helpdeskPhone', false)}
                                label={t('HELP_DESK')}
                            />
                        </Grid>

                        <Grid item xs sm={6}>
                            <DropzoneAreaBase
                                dropzoneClass={classes.myDropZone}
                                fileObjects={files}
                                acceptedFiles={['image/*']}
                                showPreviewsInDropzone={true}
                                onAdd={handleAdd}
                                onDelete={handleDelete}
                                dropzoneText={t('DROP_ZONE_TEXT')}
                                maxFileSize={60000000}
                                filesLimit={1}
                                height='30px'
                            />
                        </Grid>
                        <Grid item xs sm={6}>
                        <img
                            src={'data:image/jpeg;base64,'+state.image}
                            className={classes.image}
                            alt=""
                        />
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </PMCDialog>
    );
}

ParkingSetup.propTypes = {
    parking: PropTypes.object.isRequired,
    updateParking: PropTypes.func.isRequired,
};

export const UC_PARKING_EDIT = 'UC0061';




const mapStateToProps = (store) => ({
    parkingSimple: store.parkingsData.parkingSimple,
    
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingSimple
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingSetup);
