import React, {useEffect, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux/es';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {fetchParkingSimple} from '../../../redux/actions/parkings';
import Box from "@material-ui/core/Box";
import PMCDialog from "../../common/PMCDialog";


const useStyles = makeStyles((theme) => ({
}));

function ParkingImage(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        setOpenImage,
        parking,
        parkingSimple,
        fetchParkingSimple
    } = props;

    const [isFetchedSimple, setIsFetchedSimple] = useState(false);

    useEffect(()=>{
        if ( ( opened && parking && !isFetchedSimple )
        || ( opened && parking && parkingSimple && parking.parkingId !== parkingSimple.parkingId ) 
        ) {
            fetchParkingSimple(parking.parkingId).then(() => {
                setIsFetchedSimple(true);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [parking, parkingSimple, fetchParkingSimple, isFetchedSimple])

    const handleClose = () => {
        setOpenImage(false);
        setPos({ x: 0, y: 0, scale: 1 });
    }

    const [pos, setPos] = useState({ x: 0, y: 0, scale: 1 });

    const onScroll = (e) => {
        const delta = e.deltaY * -0.001;
        const newScale = pos.scale + delta;
    
        const ratio = 1 - newScale / pos.scale;
    
        setPos({
            scale: newScale,
            // x: pos.x,
            // y: pos.y,
            x: pos.x + (e.clientX - pos.x) * ratio,
            y: pos.y + (e.clientY - pos.y) * ratio
        });
        e.preventDefault();
        e.stopPropagation();
    };
    

    return (
        <PMCDialog
            size="xl"
            height="1250"
            headerColor={"#333da9"}
            actionType="saveModal"
            open={opened}
            title={t('ILUSTRATION_IMAGE')}
            onClose={handleClose}
            onKeyUp={(e) => {
                if (opened) {
                    const LEFT = 37;
                    const RIGHT = 39;
                    if (e.keyCode === LEFT ) {
                        console.log('scroll left');
                        setPos({...pos, x : pos.x-30})
                    }
                    if (e.keyCode === RIGHT) {
                        console.log('scroll left');
                        setPos({...pos, x : pos.x+30})
                    }
                }
            }}
            pos={pos}
            setPos={setPos}
        >
            <Box m={4} justifyContent='center' display='flex' >
                {parkingSimple && 
                    <div onWheelCapture={onScroll} style={{overflow: 'hidden'}}>
                        <img
                            src={'data:image/jpeg;base64,'+parkingSimple.image}
                            alt=""
                            style={{
                                transformOrigin: "0 0",
                                transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`,
                                maxHeight: 1050
                            }}
                        />
                    </div>
                }
            </Box>
        </PMCDialog>
    );
}

ParkingImage.propTypes = {
};

const mapStateToProps = (store) => ({
    parkingSimple: store.parkingsData.parkingSimple,
    
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingSimple
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingImage);
