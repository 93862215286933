import React, {useCallback, useEffect, useRef, useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import {Loading} from 'components/elements';
import {updateParkingSimple} from 'redux/actions/parkings';
import {fetchAllCodeList} from 'redux/actions/codes';
import {fetchParkingErrors} from 'redux/actions/errors';
import {cancelTask, renewTask, createTask, fetchParkingTasks, fetchParkingTasksStatistics,} from 'redux/actions/tasks';
import {fetchParkingHosts} from 'redux/actions/hosts';
import {fetchParkingAssignedUsers, fetchParkingNotAssignedUsers} from 'redux/actions/users';
import ManagementMainContent from './MainContent';
import {addSipParkingIdToUserAction, removeSipParkingIdToUserAction} from 'redux/actions/auth';
import {isComponentVisibleForUser} from "../../utils";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,
    color: theme.palette.base[500],
  },
  separator: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
  navLink: {
    cursor: 'pointer',
  },
  activeLink: {
    color: theme.palette.secondary.main,
  },
  title: {
    marginTop: theme.spacing(4),
    textTransform: 'uppercase',
  },
  mainHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

function Management(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    history,
    user,
    userUseCases,
    parkings,
    allCodeList,
    parkingTasks,
    taskImages,
    isCanceling,
    isRenewing,
    isFetchingAllList,
    parkingAssignedUsers,
    parkingNotAssignedUsers,
    parkingErrors,
    updateParkingSimple,
    fetchParkingTasks,
    fetchParkingAssignedUsers,
    fetchParkingNotAssignedUsers,
    fetchAllCodeList,
    fetchParkingErrors,
    createTask,
    cancelTask,
    renewTask,
    fetchParkingTasksStatistics,
    parkingTasksStatistics,
    fetchParkingHosts,
    occupancy,
    sipParkingIds,
    addSipParkingIdToUserAction,
    removeSipParkingIdToUserAction,
  } = props;

  const [showParkingSetup, setShowParkingSetup] = useState(false);
  const handleEditParking = () => {
    setShowParkingSetup(!showParkingSetup);
  };

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isFetchedCodeList, setIsFetchedCodeList] = useState(false);
  const [isLoadingCodeList, setIsLoadingCodeList] = useState(true);
  const fetchCodeList = useCallback(() => {
    if (
      user
      && user.subjectId
      && !isFetchingAllList
    ) {
      fetchAllCodeList().then(() => {
        if (!isUnMounted.current) {
          setIsFetchedCodeList(true);
          setIsLoadingCodeList(false);
        }
      }).catch(() => {
        if (!isUnMounted.current) {
          setIsFetchedCodeList(false);
          setIsLoadingCodeList(false);
        }
      });
    }
  }, [fetchAllCodeList, isFetchingAllList, user]);

  useEffect(() => {
    if (!isFetchedCodeList) {
      fetchCodeList();
    }
  }, [fetchCodeList, isFetchedCodeList, user]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedParking, setSelectedParking] = useState(null);
  const selectParking = (id) => () => {
    if (selectedIds.indexOf(id) > -1 && selectedIds.length < parkings.length) {
      setSelectedParking({});
      setSelectedIds([]);
    } else {
      const parking = parkings.find((p) => p.parkingId === id);
      setSelectedParking(parking);
      setSelectedIds([id]);
      fetchParkingHosts(id);
    }
  };
  const toggleAllParking = () => {
    setSelectedIds([]);
  };

  useEffect(() => {
    if (selectedParking) {
      const updatedParking = parkings.find((parking) => parking.parkingId === selectedParking.parkingId);
      setSelectedParking(updatedParking);
    }
  }, [parkings, selectedParking]);

  if (!user || isLoadingCodeList || parkings.length === 0) {
    return (
      <Loading />
    );
  }


  const showParkingList = selectedIds.length === 0 && isComponentVisibleForUser(UC_MAN_PARKING_LIST, userUseCases);

  const parking = selectedParking
    ? parkings.find((parking) => parking.parkingId === selectedParking.parkingId)
    : null;

  const handleClickNavLink = (level) => () => {
    setShowParkingSetup(false);
    if (level === 1) {
      toggleAllParking();
    }
  };

  return (
      <PerfectScrollbar
          options={{
            suppressScrollX: true,
            minScrollbarLength: 50,
          }}
      >
        <ManagementMainContent
          updateParking={updateParkingSimple}
          user={user}
          parkings={parkings}
          userUseCases={userUseCases}
          allCodeList={allCodeList}
          selectedParking={parking}
          selectedIds={selectedIds}
          parkingTasks={parkingTasks}
          taskImages={taskImages}
          isCanceling={isCanceling}
          isRenewing={isRenewing}
          parkingAssignedUsers={parkingAssignedUsers}
          parkingNotAssignedUsers={parkingNotAssignedUsers}
          parkingErrors={parkingErrors}
          showParkingList={showParkingList}
          toggleAllParking={toggleAllParking}
          selectParking={selectParking}
          fetchParkingErrors={fetchParkingErrors}
          fetchParkingTasks={fetchParkingTasks}
          fetchParkingAssignedUsers={fetchParkingAssignedUsers}
          fetchParkingNotAssignedUsers={fetchParkingNotAssignedUsers}
          createTask={createTask}
          cancelTask={cancelTask}
          renewTask={renewTask}
          fetchParkingTasksStatistics={fetchParkingTasksStatistics}
          parkingTasksStatistics={parkingTasksStatistics}
          occupancy={occupancy}
          sipParkingIds={sipParkingIds}
          addSipParkingIdToUserAction={addSipParkingIdToUserAction}
          removeSipParkingIdToUserAction={removeSipParkingIdToUserAction}
        />

      </PerfectScrollbar>
  );
}

Management.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  parkings: PropTypes.array.isRequired,
  allCodeList: PropTypes.array.isRequired,
  parkingTasks: PropTypes.array.isRequired,
  taskImages: PropTypes.array.isRequired,
  isCanceling: PropTypes.bool.isRequired,
  isRenewing: PropTypes.bool.isRequired,
  isFetchingAllList: PropTypes.bool.isRequired,
  parkingAssignedUsers: PropTypes.array.isRequired,
  parkingNotAssignedUsers: PropTypes.array.isRequired,
  parkingErrors: PropTypes.array.isRequired,
  updateParkingSimple: PropTypes.func.isRequired,
  fetchAllCodeList: PropTypes.func.isRequired,
  fetchParkingErrors: PropTypes.func.isRequired,
  fetchParkingTasks: PropTypes.func.isRequired,
  fetchParkingAssignedUsers: PropTypes.func.isRequired,
  fetchParkingNotAssignedUsers: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  cancelTask: PropTypes.func.isRequired,
  renewTask: PropTypes.func.isRequired,
  fetchParkingTasksStatistics: PropTypes.func.isRequired,
  parkingTasksStatistics: PropTypes.array.isRequired,
  fetchParkingHosts: PropTypes.func.isRequired,
  occupancy: PropTypes.array.isRequired,
};

Management.defaultProps = {
  user: null,
};

const mapStateToProps = (store) => ({
  user: store.authData.user,
  parkings: store.parkingsData.parkings,
  allCodeList: store.codesData.allCodeList,
  isFetchingAllList: store.codesData.isFetchingAllList,
  parkingTasks: store.tasksData.parkingTasks,
  taskImages: store.tasksData.taskImages,
  isCanceling: store.tasksData.isCanceling,
  isRenewing: store.tasksData.isRenewing,
  parkingAssignedUsers: store.usersData.parkingAssignedUsers,
  parkingNotAssignedUsers: store.usersData.parkingNotAssignedUsers,
  parkingErrors: store.errorsData.parkingErrors,
  parkingTasksStatistics: store.tasksData.parkingTasksStatistics,
  occupancy: store.parkingsData.occupancy,
  sipParkingIds: store.authData.sipParkingIds,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  updateParkingSimple,
  fetchAllCodeList,
  fetchParkingErrors,
  fetchParkingTasks,
  fetchParkingAssignedUsers,
  fetchParkingNotAssignedUsers,
  createTask,
  cancelTask,
  renewTask,
  fetchParkingTasksStatistics,
  fetchParkingHosts,
  addSipParkingIdToUserAction,
  removeSipParkingIdToUserAction,
}, dispatch);

const UC_MAN_PARKING_LIST='UC0058';
const UC_MAN_EDIT = 'UC0061';

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Management);
