import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ParkingCard, ParkingCardCollapsed } from 'components/common';
import ParkingImage from 'components/modals/Parking/ParkingImage';

const useStyles = makeStyles((theme) => ({
  container: {
    //marginTop: theme.spacing(3.75),
  },
  title: {
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
  },
}));

function ParkingList(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    parkings,
    selectedIds,
    selectParking,
    toggleAllParking,
    calls,
    sseParkingHosts,
    addSipParkingIdToUserAction,
    removeSipParkingIdToUserAction,
    sipParkingIds,
    userUseCases,
    editable,
    handleEdit,
    parkingLabel
  } = props;

  const sortedParkings = parkings.sort((a, b) => a.parkingId - b.parkingId);

  const selected = selectedIds.length === 0
    ? sortedParkings
    : sortedParkings.filter((p) => selectedIds.indexOf(p.parkingId) > -1);
  const unselected = selectedIds.length === 0
    ? []
    : sortedParkings.filter((p) => selectedIds.indexOf(p.parkingId) === -1);

  const [openImage, setOpenImage] = useState(false);
  const [showParkingImage, setShowParkingImage] = useState();

  return (
    <>
    <div className={classes.container}>
    {parkingLabel && (
      <Typography
        variant="h4"
        className={classes.title}
        onClick={toggleAllParking}
      >
        {parkingLabel}
      </Typography>)
    }
      <div className={classes.list}>
        {selected.map((parking) => (
          <ParkingCard
            editable={editable}
            handleEdit={handleEdit}
            userUseCases={userUseCases}
            addSipParkingIdToUserAction={addSipParkingIdToUserAction}
            removeSipParkingIdToUserAction={removeSipParkingIdToUserAction}
            parking={parking}
            handleClick={selectParking}
            key={parking.parkingId}
            calls={calls}
            sipParkingIds={sipParkingIds}
            sseParkingHosts={sseParkingHosts}
            setShowParkingImage={setShowParkingImage}
            setOpenImage={setOpenImage}
          />
        ))}

        {unselected.map((parking) => (
          <ParkingCardCollapsed
            editable={editable}
            handleEdit={handleEdit}
            parking={parking}
            handleClick={selectParking}
            key={parking.parkingId}
            calls={calls}
            sseParkingHosts={sseParkingHosts}
            setShowParkingImage={setShowParkingImage}
            setOpenImage={setOpenImage}

          />
        ))}
      </div>
    </div>
    <ParkingImage
                parking={showParkingImage}
                opened={openImage}
                setOpenImage={setOpenImage}
            />
    </>
  );
}

ParkingList.propTypes = {
  parkings: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  selectParking: PropTypes.func.isRequired,
  toggleAllParking: PropTypes.func.isRequired,
};

export default ParkingList;
