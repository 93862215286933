import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAccessList: false,
    isUpdating: false,
    isCreating: false,
    isConnecting: false,
    parkings: [],
    parkingAccesses: [],
    selectedHostName: null,
    selectedParking: null,
    parkingSimple: null,
    errors: '',
    cardNumber: null,
    action: false,
    isFetchingParkingsForParkingConfiguration: false,
    isFetchingParkingSimple: false,
    parkingsForParkingConfiguration: [],
    isFetchingEventCategoriesForParkingConfiguration: false,
    isUpdatingEventCategoriesForParkingConfiguration: false,
    eventCategoriesForParkingConfiguration: [],
    isUpdatingZoneVisibilityForParkingConfiguration: false,
    isRestarting: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PARKINGS_FETCH_SUCCESS:
            let result = {
                ...state,
                isFetchingList: false,
                parkings: action.payload.parkings,
                events: action.payload.events,
                parkingHosts: action.payload.parkingHosts,
                occupancy: action.payload.occupancy,
                cardNumber: action.payload.cardNumber,
                posStatutes: action.payload.posStatutes,
                soundId: action.payload.soundId,
                action: !state.action,
            };

            if (result.parkings === null) result.parkings = state.parkings;
            if (result.events === null) result.events = state.events;
            if (result.parkingHosts === null) result.parkingHosts = state.parkingHosts;
            if (result.occupancy === null) result.occupancy = state.occupancy;
            if (result.posStatutes === null) result.posStatutes = state.posStatutes;
            return result;
        case types.PARKING_FETCH_SIMPLE_SUCCESS:
            console.log('PARKING_FETCH_SIMPLE_SUCCESS action.payload', action.payload)
            return {
                ...state,
                isFetchingParkingSimple: false,
                parkingSimple: action.payload.parking,
            };
        case types.PARKING_FETCH_SIMPLE_FAIL:
            return {
                ...state,
                isFetchingParkingSimple: false,
                errors: action.payload.error,
            };
        case types.PARKING_FETCH_SIMPLE_REQUEST:
            return {
                ...state,
                isFetchingParkingSimple: true,
            };
    
        case types.PARKINGS_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                parkings: action.payload.parkings,
            };
        case types.PARKINGS_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.PARKINGS_UPDATE_SIMPLE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case types.PARKINGS_UPDATE_SIMPLE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                parkingSimple: action.payload.parkings,
            };
        case types.PARKINGS_UPDATE_SIMPLE_FAIL:
            return {
                ...state,
                isUpdating: false,
                errors: action.payload.error,
            };
        case types.PARKING_RESTART_REQUEST:
            return {
                ...state,
                isRestarting: true,
            };
        case types.PARKING_RESTART_SUCCESS:
            return {
                ...state,
                isRestarting: false,
            };
        case types.PARKING_RESTART_FAIL:
            return {
                ...state,
                isRestarting: false,
                errors: action.payload.error,
            };
        case types.PARKINGS_CREATE_REQUEST:
            return {
                ...state,
                isCreating: true,
            };
        case types.PARKINGS_CREATE_SUCCESS:
            return {
                ...state,
                isCreating: false,
                parkings: action.payload.parkings,
            };
        case types.PARKINGS_CREATE_FAIL:
            return {
                ...state,
                isCreating: false,
                errors: action.payload.error,
            };
        case types.PARKINGS_CONNECT_REQUEST:
            return {
                ...state,
                isConnecting: true,
            };
        case types.PARKINGS_CONNECT_SUCCESS:
            return {
                ...state,
                isConnecting: false,
            };
        case types.PARKINGS_CONNECT_FAIL:
            return {
                ...state,
                isConnecting: false,
                errors: action.payload.error,
            };
        case types.PARKING_ACCESS_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAccessList: true,
            };
        case types.PARKING_ACCESS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAccessList: false,
                parkingAccesses: action.payload.parkingAccesses,
            };
        case types.PARKING_ACCESS_FETCH_FAIL:
            return {
                ...state,
                isFetchingAccessList: false,
                errors: action.payload.error,
            };
        case types.PARKING_CONFIGURATION_FETCH_REQUEST:
            return {
                ...state,
                isFetchingParkingsForParkingConfiguration: true,
            };
        case types.PARKING_CONFIGURATION_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingParkingsForParkingConfiguration: false,
                parkingsForParkingConfiguration: action.payload.parkingsForParkingConfiguration,
            };
        case types.PARKING_CONFIGURATION_FETCH_FAIL:
            return {
                ...state,
                isFetchingParkingsForParkingConfiguration: false,
                errors: action.payload.error,
            };
        case types.PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_REQUEST:
            return {
                ...state,
                isFetchingEventCategoriesForParkingConfiguration: true,
            };
        case types.PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingEventCategoriesForParkingConfiguration: false,
                eventCategoriesForParkingConfiguration: action.payload.eventCategories,
            };
        case types.PARKING_CONFIGURATION_EVENT_CATEGORIES_FETCH_FAIL:
            return {
                ...state,
                isFetchingEventCategoriesForParkingConfiguration: false,
                errors: action.payload.error,
            };

        case types.PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingEventCategoriesForParkingConfiguration: true,
            };
        case types.PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdatingEventCategoriesForParkingConfiguration: false,
                eventCategoriesForParkingConfiguration: action.payload.eventCategories,
            };
        case types.PARKING_CONFIGURATION_EVENT_CATEGORIES_UPDATE_FAIL:
            return {
                ...state,
                isUpdatingEventCategoriesForParkingConfiguration: false,
                errors: action.payload.error,
            };
        case types.PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_REQUEST:
            return {
                ...state,
                isUpdatingZoneVisibilityForParkingConfiguration: true,
            };
        case types.PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdatingZoneVisibilityForParkingConfiguration: false,
                parkingsForParkingConfiguration: action.payload.parkingsForParkingConfiguration,
            };
        case types.PARKING_CONFIGURATION_ZONE_VISIBILITY_UPDATE_FAIL:
            return {
                ...state,
                isUpdatingZoneVisibilityForParkingConfiguration: false,
                errors: action.payload.error,
            };
        case types.PROCESS_ACTION:
            return {
                ...state,
                cardNumber: null,
            };
        case types.PARKING_HOSTNAME_SELECT:
            return {
                ...state,
                selectedHostName: action.payload.selectedHostName,
            };
        case types.PARKING_PARKING_SELECT:
            return {
                ...state,
                selectedParking: action.payload.selectedParking,
            };
        default:
            return state;
    }
};
